import { useMemo } from "react";

const useConstants = () => {
  const constants = useMemo(
    () => ({
      companyName: "Arctic Webfox",
      slogan: "Het web is ons jachtgebied",
      baseUrl: "https://www.arcticwebfox.nl",
      foundingDate: new Date("02/01/2019"),
      email: "info@arcticwebfox.nl",
      phone: {
        default: "06-46519312",
        html: "+31646519312",
        intl: "+31646519312",
      },
      address: {
        street: "Utrechtseweg",
        number: "16",
        numberAddition: "a",
        postCode: "3927 AV",
        city: "Renswoude",
        country: "Nederland",
      },
      vat: "NL002418850B36",
      kvk: "74003496",
      googleMapsUrl:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyCuPx_-8OxH8K1GR4aYrcgYFOhuHy7cS24&q=Utrechtseweg+16,+3927+AV+Renswoude",
      socialMedia: {
        facebook: "https://www.facebook.com/nielsvdpol",
        instagram: "https://www.instagram.com/nielsvandepol",
        linkedin: "https://www.linkedin.com/in/nielsvandepol",
      },
    }),
    []
  );

  return constants;
};

export default useConstants;
