import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons/faBullhorn";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faFileCode } from "@fortawesome/free-solid-svg-icons/faFileCode";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons/faPencilRuler";
import { faPercent } from "@fortawesome/free-solid-svg-icons/faPercent";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

const { library } = require("@fortawesome/fontawesome-svg-core");

library.add(
  faFacebook,
  faInstagram,
  faLinkedin,
  faBars,
  faBuilding,
  faBullhorn,
  faEnvelope,
  faFileCode,
  faGlobe,
  faMapMarkerAlt,
  faPencilRuler,
  faPercent,
  faPhone,
  faSearchPlus,
  faTimes
);
