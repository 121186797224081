import clsx, { ClassValue } from "clsx";
import React, { FC, HTMLAttributes } from "react";

const VariantMapping: Record<Variants, Components> = {
  title: "h1",
  subtitle: "h2",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  paragraph: "p",
  "paragraph-primary": "p",
};

type Variants =
  | "title"
  | "subtitle"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "paragraph"
  | "paragraph-primary";
type Components = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "a";

type TypographyComponentProps = HTMLAttributes<HTMLHeadingElement> &
  HTMLAttributes<HTMLParagraphElement> &
  HTMLAttributes<HTMLSpanElement> &
  HTMLAttributes<HTMLAnchorElement>;

interface TypographyProps extends TypographyComponentProps {
  variant: Variants;
  component?: (typeof VariantMapping)[keyof typeof VariantMapping];
  color?:
    | "text-primary"
    | "text-gray"
    | "text-gray-dark"
    | "text-blue"
    | "text-white";
  size?: "text-base" | "text-xs" | "text-2xl" | "text-3xl";
  spacing?: boolean;
}

const Typography: FC<TypographyProps> = ({
  variant,
  component,
  color = "text-gray-dark",
  size,
  spacing,
  children,
  className,
  ...remainingProps
}) => {
  const Component = component ?? VariantMapping[variant];

  const SpacingStyle: ClassValue = {
    "mb-2.5": spacing,
    "mt-[2.125rem]": variant === "h1" && spacing,
    "mt-8": variant === "h2" && spacing,
    "mt-[1.875rem]": variant === "h3" && spacing,
    "mt-6": variant === "h4" && spacing,
    "mt-5": variant === "h5" && spacing,
    "mt-4": variant === "h6" && spacing,
  };

  const VariantStyle: ClassValue = {
    "font-primary font-extrabold text-shadow tracking-widest sm:text-8xl lg:text-9xl text-7xl":
      variant === "title",
    "font-primary text-4xl sm:text-5xl lg:text-6xl": variant === "subtitle",
    "font-primary font-extrabold":
      variant === "h1" ||
      variant === "h2" ||
      variant === "h3" ||
      variant === "h4" ||
      variant === "h5" ||
      variant === "h6",
    "font-primary": variant === "paragraph-primary",
    "font-sans": variant === "paragraph",
  };

  const sizeStyle = !size
    ? {
        "text-6xl": variant === "h1",
        "text-5xl": variant === "h2",
        "text-3xl": variant === "h3",
        "text-2xl": variant === "h4",
        "text-lg": variant === "h5" || variant === "paragraph",
        "text-base": variant === "h6",
      }
    : size;

  return (
    <Component
      className={clsx(
        className,
        color,
        "hyphens-auto break-words",
        VariantStyle,
        SpacingStyle,
        sizeStyle
      )}
      {...remainingProps}
    >
      {children}
    </Component>
  );
};

export default Typography;
