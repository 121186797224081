import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import * as Portal from "@radix-ui/react-portal";
import { Analytics } from '@vercel/analytics/react';
import { NextPage } from "next";
import { CorporateContactJsonLd, DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Image from "next/image";
import { useRouter } from "next/router";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import TagManager from "react-gtm-module";
import useConstants from "../hooks/useConstants";
import DefaultLayout from "../layouts/DefaultLayout";
import GradientLayout from "../layouts/nested/GradientLayout";
import "../lib/fontawesome";
import imageLoader from "../public/loader.png";
import "../styles/globals.css";

config.autoAddCss = false;

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <DefaultLayout>
        <GradientLayout>{page}</GradientLayout>
      </DefaultLayout>
    ));

  const { companyName, slogan, baseUrl } = useConstants();
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>();
  const timeout = useRef<NodeJS.Timeout>();
  const LOAD_DELAY = 250;
  const tagManagerId = "GTM-MNT3TBS";

  useEffect(() => {
    tagManagerId && TagManager.initialize({ gtmId: tagManagerId });
  }, [tagManagerId]);

  const onLoadHandler = useCallback(() => {
    if (loading) return;

    timeout.current = setTimeout(() => {
      setLoading(true);
    }, LOAD_DELAY);
  }, [loading]);

  const onComplete = useCallback(() => {
    setLoading(false);

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", onLoadHandler);
    router.events.on("routeChangeComplete", onComplete);
    router.events.on("routeChangeError", onComplete);

    return () => {
      router.events.off("routeChangeStart", onLoadHandler);
      router.events.off("routeChangeComplete", onComplete);
      router.events.off("routeChangeError", onComplete);
    };
  }, [onComplete, onLoadHandler, router.events]);

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - ${companyName}`}
        title={slogan}
        openGraph={{
          type: "website",
          url: router.asPath,
          locale: "nl_NL",
          title: companyName,
          description: slogan,
          images: [
            {
              url: `${baseUrl}/logo.png`,
              width: 200,
              height: 200,
              alt: `Logo ${companyName}`,
            },
          ],
        }}
      />
      <CorporateContactJsonLd
        url={baseUrl}
        logo={`${baseUrl}/logo.png`}
        contactPoint={[
          {
            telephone: "+1-877-453-1304",
            contactType: "customer service",
            contactOption: "TollFree",
            availableLanguage: ["English", "Dutch"],
          },
        ]}
      />

      {loading && (
        <Portal.Root>
          <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-white bg-opacity-90">
            <Image
              src={imageLoader}
              width={200}
              height={200}
              alt={`Logo ${companyName}`}
              className="animate-pulse"
            />
          </div>
        </Portal.Root>
      )}

      <>{getLayout(<Component {...pageProps} />)}</>
      
      <Analytics />
    </>
  );
}
export default MyApp;
