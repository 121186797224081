import React, { forwardRef } from "react";

const FooterMountains = forwardRef<HTMLDivElement>(function FooterMountains(
  props,
  ref
) {
  return (
    <div
      className="pointer-events-none relative top-[calc(var(--footerImage-y)*-0.5)] mb-[calc((var(--footerImage-y)*-0.5)-2rem)]"
      ref={ref}
    >
      <div className="flex items-center justify-center overflow-hidden pt-8">
        <div className="h-12 w-12 rotate-45 bg-white" />
        <div className="h-24 w-24 rotate-[44deg] bg-white" />
        <div className="h-32 w-32 rotate-45 bg-white" />
        <div className="h-36 w-36 rotate-[44deg] bg-white" />
        <div className="h-16 w-16 rotate-45 bg-white" />

        <div className="hidden h-12 w-12 rotate-45 bg-white sm:block" />
        <div className="hidden h-20 w-20 rotate-45 bg-white sm:block" />

        <div className="hidden h-24 w-24 rotate-45 bg-white md:block" />
        <div className="hidden h-28 w-28 rotate-45 bg-white md:block" />

        <div className="hidden h-12 w-12 rotate-[46deg] bg-white lg:block" />
        <div className="hidden h-32 w-32 rotate-45 bg-white lg:block" />

        <div className="hidden h-16 w-16 rotate-45 bg-white xl:block" />
        <div className="hidden h-36 w-36 rotate-[47deg] bg-white xl:block" />
      </div>
    </div>
  );
});

export default FooterMountains;
