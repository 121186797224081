import { FC, PropsWithChildren, useEffect, useRef } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const header = useRef<HTMLElement>(null);
  const footer = useRef<HTMLElement>(null);

  useEffect(() => {
    const bodyHeight =
      header.current && footer.current
        ? header.current.offsetHeight + footer.current.offsetHeight + "px"
        : "100vh";

    document.documentElement.style.setProperty("--body-y", bodyHeight);

    return () => {
      document.documentElement.style.removeProperty("--body-y");
    };
  }, []);

  return (
    <>
      <Header ref={header} />
      {children}
      <Footer ref={footer} />
    </>
  );
};

export default DefaultLayout;
